<template>
  <div class="top-register-form">
    <img
      :src="`/img/top/register-main${isPc ? '' : '-sp'}.png`"
      alt=""
      width="320"
      height="312"
      class="top-register-form__img"
    />
    <div class="register-form__form">
      <div class="form__box">
        <template v-for="(i, key) in items" :key="key">
          <TopCheckbox
            :id="String(i.id)"
            :label="i.label"
            :value="i.label"
            :on-click="onClick"
            :is-checked="targets.includes(i.label)"
          />
        </template>
      </div>
      <div class="form__button">
        <ButtonsTopDefaultButton
          :is-disabled="targets.length === 0"
          text="この条件で利用登録する"
          color-type="secondary"
          :on-click="onSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FOCUS_ITEMS } from '~/const/regist/focusItems';

type Props = {
  isPc: boolean;
};

defineProps<Props>();

const router = useRouter();
const targets = useState<string[]>(() => []);

const items = computed(() => {
  return FOCUS_ITEMS.map((f: { [key: number]: string }) => {
    const id = Number(Object.keys(f)[0]);
    const label = f[id];

    return {
      id,
      label,
    };
  });
});

const onClick = (value: string) => {
  const exsist = targets.value.includes(value);
  if (exsist) {
    targets.value = targets.value.filter((t) => t !== value);
  } else {
    targets.value.push(value);
  }
};

const onSubmit = () => {
  router.push({
    path: '/registration',
    query: {
      focusItems: targets.value,
    },
  });
};

onMounted(() => {
  targets.value = [];
});

onBeforeMount(() => {
  targets.value = [];
});
</script>
