<template>
  <div class="top-custom-main-button">
    <p class="top-custom-main-button__text">
      最短<span class="sub-text">1</span>分で登録完了
    </p>
    <ButtonsTopDefaultButton
      text="ご利用登録はこちら"
      color-type="secondary"
      type="link"
      :path="fullUrl('/registration')"
    />
  </div>
</template>
