export const FOCUS_ITEMS = [
  { 1: '年収アップ' },
  { 2: '経験を積める' },
  { 3: '予算が割り当てられる' },
  { 4: '一緒に働きたいと思える人' },
  { 5: 'IPO準備中' },
  { 6: 'テレワーク・時短勤務有' },
  { 7: '判断基準が合う' },
  { 8: '裁量権がある' },
  { 9: '残業なし' },
  { 10: '年収が下がらない' },
  { 11: '大手企業' },
  { 12: 'ベンチャー企業' },
] as const;
